import React, { useState } from "react";
import { handleSignInEmail } from "../../hooks/useFirebase";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { LoadingSpinner } from "../atoms/LoadingSpinner";
import Logo from "../../assets/logo.png";

export default function SigninForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setError("Email and password are required.");
      return;
    }

    setLoading(true);

    try {
      const res = await handleSignInEmail(email, password);
      console.log(res);
      setError("");
      const redirectUrl =
        new URLSearchParams(window.location.search).get("redirectUrl") ||
        "https://syncmydevice.com/auth/validic_connect_test/callback";
      window.location.href = `${redirectUrl}?status=success`;
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded shadow-lg w-full max-w-sm"
      >
        <div className="flex items-center justify-center mb-6">
          <img src={Logo} width={80} height={80} />
        </div>
        <h2 className="text-2xl font-bold mb-4 text-center">Sign In</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Email</label>
          <input
            type="email"
            className="w-full px-3 py-2 border border-gray-300 rounded"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Password</label>
          <div className="4 w-full px-3 py-2 border border-gray-300 rounded flex items-center">
            <input
              type={showPassword ? "text" : "password"}
              className="w-full"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div
              onClick={() => setShowPassword(!showPassword)}
              className="cursor-pointer"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 flex justify-center items-center"
        >
          {loading ? <LoadingSpinner /> : "Sign In"}
        </button>
      </form>
    </div>
  );
}
