import React from "react";
import SigninForm from "../components/forms/SigninForm";

export default function Signin() {
  return (
    <div>
      <SigninForm />
    </div>
  );
}
