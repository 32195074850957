import logo from "./logo.svg";
import Signin from "./pages/Signin";
function App() {
  return (
    <div>
      <Signin />
    </div>
  );
}

export default App;
