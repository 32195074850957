import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import firebaseInitAuth from "../firebase/firebase.init";

firebaseInitAuth();

const auth = getAuth();

export const handleSignInEmail = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};
